import HKSARVideo from "../Utils/HKSARVideo";

let instance = null;

export default class Experience {
    constructor() {
        // Singleton
        if (instance) {
            return instance;
        }
        instance = this;

        // Global access
        window.experience = this;

        this.scanCount = 0;
        this.scanCountMax = 6;

        this.HKSARVideos = [
            new HKSARVideo("artTarget", "artVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 36 },
                { start: 38, end: 57 }
            ]),
            new HKSARVideo("foodTarget", "foodVideo", [
                { start: 0, end: 18 },
                { start: 19, end: 37 },
                { start: 38, end: 66 }
            ]),
            new HKSARVideo("infraTarget", "infraVideo", [
                { start: 0, end: 39 },
                { start: 40, end: 56 },
                { start: 58, end: 84 }
            ]),
            new HKSARVideo("lifestyleTarget", "lifestyleVideo", [
                { start: 0, end: 23 },
                { start: 24, end: 50 },
                { start: 51, end: 71 }
            ]),
            new HKSARVideo("natureTarget", "natureVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 39 },
                { start: 40, end: 60 }
            ]),
            new HKSARVideo("workTarget", "workVideo", [
                { start: 0, end: 19 },
                { start: 20, end: 36 },
                { start: 37, end: 67 }
            ])
        ];

        this.mainPlayer = document.getElementById("mainPlayer");

        this.init();
    }

    init() {
        console.log("Experience initialized");
    }
}
