export default class HKSARVideo {
    constructor(entityId, videoId, sections = []) {
        this.target = document.getElementById(entityId);
        this.video = document.getElementById(videoId);

        if (!this.target || !this.video) {
            console.error(`Element(s) not found: target (${entityId}), video (${videoId})`);
            return;
        }

        this.sections = sections;
        this.currentSection = null;
        this.currentTime = 0;
        this.scanned = false;

        this.init();
    }

    init = () => {
        // Set a random section to play
        this.jumpToRandomSection();
        
        // Set event listeners for AR target found and lost events
        this.target.addEventListener("targetFound", this.onTargetFound);
        this.target.addEventListener("targetLost", this.onTargetLost);

        // Synchronize video time with sections
        this.video.addEventListener("timeupdate", this.onTimeUpdate);
    }

    onTargetFound = () => {
        this.setScanned(true);
        const mainPlayer = window.experience.mainPlayer;

        if (mainPlayer && !mainPlayer.paused) {
            this.video.currentTime = mainPlayer.currentTime;
            mainPlayer.pause();
        }

        this.playVideo();
    }

    onTargetLost = () => {
        const mainPlayer = window.experience.mainPlayer;

        if (mainPlayer && this.video.currentTime < this.currentSection.end) {
            if (mainPlayer.src !== this.video.src) {
                mainPlayer.src = this.video.src;
            }
            mainPlayer.currentTime = this.video.currentTime;
            mainPlayer.play();
        } else {
            this.pauseVideo();
        }
    }

    onTimeUpdate = () => {
        // Jump to a new section if the current one is finished
        if (this.video.currentTime >= this.currentSection.end) {
            this.jumpToRandomSection();
        }
    }

    setScanned = (scanned) => {
        // Set the scanned state only if it has changed
        if (scanned !== this.scanned) {
            this.scanned = scanned;
            // Ensure scanCount logic is managed externally (since it's not defined here)
            if (scanned && window.experience) {
                window.experience.scanCount++;
                console.log("Scan count:", window.experience.scanCount);
                // trigger animation
            }
        }
    }

    getRandomSection = () => {
        // Get a random section from the available sections
        return this.sections[Math.floor(Math.random() * this.sections.length)];
    }

    jumpToRandomSection = () => {
        // Jump to a randomly selected section and set it as the current one
        this.currentSection = this.getRandomSection();
        this.video.currentTime = this.currentSection.start;
    }

    playVideo = () => {
        this.video.play();
    }

    pauseVideo = () => {
        this.video.pause();
    }

    // Clean up event listeners to prevent memory leaks
    destroy = () => {
        this.target.removeEventListener("targetFound", this.onTargetFound);
        this.target.removeEventListener("targetLost", this.onTargetLost);
        this.video.removeEventListener("timeupdate", this.onTimeUpdate);
    }
}
